import Tinymce from "vue-tinymce-editor";
import {
	Datetime
} from 'vue-datetime';
import {
	required,
	maxLength
} from "vuelidate/lib/validators";

export default {
	props: ["obNoticia", "blRascunho"],
	components: {
		Tinymce
	},
	data() {
		return {
			arCategorias: [],
			blDialogExcluir: false,
			blExcluindo: false,
			blErro: false,
			blSalvando: false,
			blSalvandoRascunho: false,
			datepicker: false,
			obForm: {
				id: 0,
				titulo: "",
				created_at: new Date().toISOString(),
				subtitulo: "",
				texto: "",
				resumo: "",
				prioridade: 0,
				rascunho: false,
				id_noticia: null,
				id_categoria: null,
				created_at: null,
				data: null
			},
			obOptions: {
				language_url: process.env.VUE_APP_ROOT_API + "../langs-tinymce/pt_BR.js",
				//images_upload_url: process.env.VUE_APP_ROOT_API + "post/uploadImagem",
				convert_urls: false
			}
		};
	},
	validations: {
		obForm: {
			titulo: {
				required
			},
			texto: {
				required
			},
			resumo: {
				required,
				maxLength: maxLength(240)
			},
			id_categoria: {
				required
			},
			data: {
				required
			},
			prioridade: {
				required
				/*min: min(0),
				max: max(99)*/
			}
		}
	},
	created() {
		this.preencherFormulario();
		this.listarCategorias();
		console.log(this.obForm);
	},
	watch: {
		obNoticia() {
			this.preencherFormulario();
		}
	},
	methods: {
		listarCategorias() {
			this.$root.$api.get('categoria/listar').then(
				response => {
					this.arCategorias = response.retorno;
				}
			)
		},
		editorInit() {
			if (this.$props.obNoticia)
				this.$refs.refTinymce.editor.setContent(
					this.$props.obNoticia.texto
				);
		},
		preencherFormulario() {
			if (this.$props.obNoticia) {
				this.$root.$utils.preencheFormularios(
					this.obForm,
					this.$props.obNoticia
				);
				this.obForm.data = this.$props.obNoticia.data.split('/').reverse().join('-');
			}
		},
		mensagemErro(strCampo) {
			return this.$utils.handleErrors(strCampo, this);
		},
		parseDate() {
			if (!this.obForm.data) return null;

			const [year, month, day] = this.obForm.data.split("-");
			return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
		},
		validarFormulario() {
			this.blErro = false;

			this.$v.obForm.$touch();

			if (this.$v.obForm.$invalid) {
				this.blErro = true;
			}
		},
		salvarRascunho() {
			this.validarFormulario();

			if (!this.blErro) {
				this.blSalvandoRascunho = true;

				console.log(
					"1: ",
					this.$props.obNoticia,
					this.$props.blRascunho
				);

				if (this.$props.obNoticia && !this.$props.blRascunho) {
					console.log("2");
					this.obForm.id_noticia = this.$props.obNoticia.id;
					this.obForm.id = 0;
				}

				this.obForm.rascunho = true;
				this.submit();
			}
		},
		publicar() {
			this.validarFormulario();

			if (!this.blErro) {
				this.blSalvando = true;
				this.obForm.rascunho = false;

				this.submit();
			}
		},
		submit() {
			this.$root.$api
				.post("noticia/salvar", this.obForm)
				.then(response => {
					this.blSalvando = false;
					this.blSalvandoRascunho = false;

					if (!this.$route.params.id) {
						this.$router.push({
							name: "admin-noticia-cadastro",
							params: {
								id: response.retorno.id
							}
						});
					}

					this.$emit("salvou", response.retorno);
				});
		},
		excluir() {
			this.blExcluindo = true;

			this.$root.$api
				.delete("noticia/excluirCompleto/" + this.obNoticia.id)
				.then(response => {
					this.blDialogExcluir = false;

					this.$router.push({
						name: "admin-noticia"
					});
				});
		}
	}
};