import formulario from "./Formulario/Formulario.vue";

export default {
    name: "admin-formulario-noticia",
    components: {
        formulario
    },
    data() {
        return {
            tab: 0,
            blLoading: true,
            obNoticia: null
        };
    },
    created() {
        if (this.$route.params.id) {
            this.obter(this.$route.params.id);
        } else {
            this.blLoading = false;
        }
    },
    methods: {
        obter(id) {
            this.$root.$api.get("noticia/obter/" + id).then(response => {
                this.obNoticia = response.retorno;

                this.blLoading = false;
            });
        },
        atualizarNoticia(obNoticia) {
            this.blLoading = true;
            this.obNoticia = obNoticia;
            this.blLoading = false;

            if (obNoticia.noticia_rascunho == null) {
                console.log("trocou aba");
                this.tab = 0;
            }
        }
    }
};
